<template>
    <div class="medical_info">

        <div class="treat_main_list" :class="{'bai': tcount <= 0}" >
            <van-list
                    v-model="tloading"
                    :finished="tfinished"
                    @load="medicalHistoryQry"
                    :offset="30"
                    :immediate-check="false"
                    v-if="tlist.length>0"
                    finished-text="加载完成">
                <div class="one" v-for="(item, i) in tlist" :key="i" @click="detail(item.id, item.type)">
                    <div class="user_info">
                        <div class="name_time">
                            <div class="left">
                                <div class="type">{{item.seetype}}</div>
                                <div class="name">{{item.name}}</div>
                            </div>
                            <div class="right">{{item.seetime}}</div>
                        </div>
                        <div class="sex_age_phone">
                            <span>{{item.sex}} . {{item.age}}岁</span>
                            <span v-if="item.phone">{{item.phone}}</span>
                        </div>
                    </div>
                    <div class="title_top">
                        <div class="left">主诉</div>
                        <div class="right left1" v-if="item.complaint && item.complaint.length > 15">{{item.complaint || '--'}}</div>
                        <div class="right" v-else>{{item.complaint || '--'}}</div>
                    </div>
                    <div class="title_top">
                        <div class="left">中医诊断</div>
                        <div class="right left1" v-if="item.diagnose && item.diagnose.length > 15">{{item.diagnose || '--'}}</div>
                        <div class="right" v-else>{{item.diagnose || '--'}}</div>
                    </div>
                    <div class="title_top" v-if="item.treatinfo">
                        <div class="left">理疗单</div>
                        <div class="right left1" v-if="item.treatinfo && item.treatinfo.length > 15">{{item.treatinfo || '--'}}</div>
                        <div class="right" v-else>{{item.treatinfo || '--'}}</div>
                    </div>
                    <div class="title_top" v-if="item.prescription">
                        <div class="left">药方</div>
                        <div class="right left1" v-if="item.prescription && item.prescription.length > 15">{{item.prescription || '--'}}</div>
                        <div class="right" v-else>{{item.prescription || '--'}}</div>
                    </div>
                    <div class="title_top" v-if="item.dosage">
                        <div class="left">剂量</div>
                        <div class="right">{{item.dosage}}剂</div>
                    </div>
                    <div class="title_top" v-if="item.doctor">
                        <div class="left">开单医生</div>
                        <div class="right">{{item.doctor}}</div>
                    </div>
                    <div class="title_top" v-if="item.typename">
                        <div class="left">订单类型</div>
                        <div class="right status0">{{item.typename}}</div>
                    </div>
                    <div class="title_top" v-if="item.statusname">
                        <div class="left">订单状态</div>
                        <div class="right">{{item.statusname}}</div>
                    </div>
                </div>
            </van-list>
            <div class="has_no_data" v-else>
                暂无病历数据
            </div>
        </div>
    </div>
</template>

<script>
    import { wxmedicalhistoryqry } from '@/api/medical'
    import { mapGetters } from 'vuex'
    import { Toast } from 'vant';
    // import Vue from 'vue';

    export default {
        computed: {
            ...mapGetters([
                'username','isrefresh'
            ]),
        },
        data() {
            return {
                index: '0',
                tlist: [],
                // 无限加载
                tloading: false,
                tfinished: false,
                tcount: 0,
                tpage: 1,
                userid:'',
                customerid:'',
            }
        },
        mounted () {
            this.$store.dispatch('hideOrShowNav', false)
            this.userid = this.$route.query.userid || ''
            this.customerid = this.$route.query.customerid || ''
            this.medicalHistoryQry()
        },
        activated() {
   
            this.$store.dispatch('hideOrShowNav', false)
            if(this.isrefresh == '1' && !this.tloading) {
                this.$store.dispatch('changeIsRefresh', '0')
                this.tpage = 1
                this.tcount = 0
                this.tlist = []
                this.tfinished = false
                this.tloading = false
                this.userid = this.$route.query.userid || ''
                this.customerid = this.$route.query.customerid || ''
                this.medicalHistoryQry()
            }
        },
        methods: {
            //患者病历查询
            medicalHistoryQry() {
                if(this.tfinished) {
                    return
                }
                Toast({
                    message: '加载中...',
                    type: 'loading',
                    duration: 0
                })
                let data = {
                    username: this.username,
                    userid: this.userid,
                    customerid: this.customerid,
                    qrypage: this.tpage
                }
                wxmedicalhistoryqry(data).then(response => {
                    if(Toast){
                        Toast.clear()
                    }
  
                    if(response.response_body && response.response_body.list){
                        this.tcount = parseInt(response.response_body.num)
                        this.tlist = this.tlist.concat(response.response_body.list)
                        this.tfinished = (this.tpage >= response.response_body.pages);
                        this.tpage = parseInt(this.tpage)+1
                    }
                    // 加载状态结束
                    this.tloading = false;
                })
            },

            detail(id, type) {
                this.$router.push({
                    path: '/medicalDetail',
                    query: {
                        userid: this.userid,
                        customerid: this.customerid,
                        id: id,
                        type: type
                    }
                })
            },

        },
    }
</script>

<style lang="scss" scoped>
    .van-info{
        top: 2px;
        right: -8px;
    }
    .van-cell{
        background-color: #F8F7FC;
        border-radius: 0.50667rem;
    }
    .bai {
        background-color: #ffffff;
    }
    .name_time .left .type{
        min-width: 30px !important;
    }
</style>